<template>
  <div class="landing">
    <div class="text-h3 text-center font-weight-bold mt-10 mt-md-8">
      Máme pro Vás slevu
    </div>
    <div class="text-h5 text-center blue-grey--text text--darken-2 mt-5">
      Získejte slevu <strong>20%</strong> na první měsíc provozu Brani nástrojů!
    </div>

<!-- COUPON -->
    <v-fade-transition>
      <v-card v-if="voucherCode" elevation="2" width="350px" class="ma-auto mt-10" dark>
        <v-card-title class="text-h4 font-weight-bold text-uppercase">{{voucherCode}}</v-card-title>
        <v-card-subtitle>Váš kupón na služby Brani.cz</v-card-subtitle>
      </v-card>
    </v-fade-transition>

<!-- ERROR -->
    <v-fade-transition>
      <v-card v-if="error" elevation="2" width="500px" class="ma-auto mt-10" dark>
        <v-card-title class="d-block text-center font-weight-bold text-uppercase">{{error}}</v-card-title>
        <v-card-subtitle></v-card-subtitle>
      </v-card>
    </v-fade-transition>

<!-- FORM -->
    <v-form v-if="!voucherCode && !error" ref="form" v-model="valid" lazy-validation>
      <v-row class="justify-center mt-15">
        <v-col cols="12" sm="6" md="4" class="py-0"><v-text-field v-model="email" label="Váš email" type="email" :rules="emailRules" required outlined></v-text-field></v-col>
        <v-col cols="12" sm="6" md="4" class="py-0"><v-text-field v-model="eshop" label="Váš Eshop" :rules="eshopRules" outlined></v-text-field></v-col>
      </v-row>
      <div class="text-center mt-5 mt-md-10">
        <v-btn class="cta-button primary" :disabled="!valid" depressed large @click="getVoucher()">Získat Můj kupón</v-btn>
        <p class="caption mt-3">Zbývájící počet kupónů: {{voucherRemain}} </p>
      </div>
    </v-form>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'LandingPage',
    data: () => ({
      valid: true,
      email: '',
      eshop: '',
      voucherRemain: 0,
      voucherCode: '',
      error: '',
      emailRules: [
       v => !!v || 'Email je zapotřebí',
       v => /.+@.+\..+/.test(v) || 'Email není validní',
      ],
      eshopRules: [
        v =>  !v || /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/.test(v) || 'Musí obsahovat validní URL. Např.: https://www.brani.cz/',
      ],
    }),
    mounted() {
      this.fetchVoucherCount();
    },
    methods: {
      getVoucher() {
          if(this.$refs.form.validate()) {
            axios.post('https://misc.infra.branibot.com/voucher/request/', {
              email: this.email,
              eshop: this.eshop,
            }).then((res) => {
              this.voucherCode = res.data.code;
            }).catch((e) => {
              this.error = e.response.data.detail;
            })
          }
      },
      fetchVoucherCount() {
        axios.get('https://misc.infra.branibot.com/voucher/count/').then((res) => {
          this.voucherRemain = res.data;
        })
      },
    }
  }
</script>

<style lang="css" >

  .v-card__text, .v-card__title {
    word-break: normal !important;
  }
  .cta-button {
    background-image: linear-gradient(210deg,#01ada0,#08acc6 85%,#0dace4);
    color: white !important;
  }

  .landing {
    max-width: 1500px;
    margin: auto;
  }
  .landing >>> *{
    z-index: 999;
  }
</style>
