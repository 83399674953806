import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"landing"},[_c('div',{staticClass:"text-h3 text-center font-weight-bold mt-10 mt-md-8"},[_vm._v(" Máme pro Vás slevu ")]),_vm._m(0),_c(VFadeTransition,[(_vm.voucherCode)?_c(VCard,{staticClass:"ma-auto mt-10",attrs:{"elevation":"2","width":"350px","dark":""}},[_c(VCardTitle,{staticClass:"text-h4 font-weight-bold text-uppercase"},[_vm._v(_vm._s(_vm.voucherCode))]),_c(VCardSubtitle,[_vm._v("Váš kupón na služby Brani.cz")])],1):_vm._e()],1),_c(VFadeTransition,[(_vm.error)?_c(VCard,{staticClass:"ma-auto mt-10",attrs:{"elevation":"2","width":"500px","dark":""}},[_c(VCardTitle,{staticClass:"d-block text-center font-weight-bold text-uppercase"},[_vm._v(_vm._s(_vm.error))]),_c(VCardSubtitle)],1):_vm._e()],1),(!_vm.voucherCode && !_vm.error)?_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,{staticClass:"justify-center mt-15"},[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c(VTextField,{attrs:{"label":"Váš email","type":"email","rules":_vm.emailRules,"required":"","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c(VTextField,{attrs:{"label":"Váš Eshop","rules":_vm.eshopRules,"outlined":""},model:{value:(_vm.eshop),callback:function ($$v) {_vm.eshop=$$v},expression:"eshop"}})],1)],1),_c('div',{staticClass:"text-center mt-5 mt-md-10"},[_c(VBtn,{staticClass:"cta-button primary",attrs:{"disabled":!_vm.valid,"depressed":"","large":""},on:{"click":function($event){return _vm.getVoucher()}}},[_vm._v("Získat Můj kupón")]),_c('p',{staticClass:"caption mt-3"},[_vm._v("Zbývájící počet kupónů: "+_vm._s(_vm.voucherRemain)+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-h5 text-center blue-grey--text text--darken-2 mt-5"},[_vm._v(" Získejte slevu "),_c('strong',[_vm._v("20%")]),_vm._v(" na první měsíc provozu Brani nástrojů! ")])}]

export { render, staticRenderFns }